import { jewlrTheme } from "@jewlr/storybook/themes"

import baseColors from "../base-colors"

const colors = {
  ...jewlrTheme.colors,
  ...baseColors,

  // TODO: Replace storybook theme primary with this blueSapphire color
  primary: jewlrTheme.colors.blueSapphire,
}

export default colors
